import { paymentsData } from './config/payments.config';
import styles from './styles.module.scss';

export const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {paymentsData.map((src) => (
            <img
              key={src}
              loading="lazy"
              src={`/images/payments/${src}.svg`}
              alt={src}
              className={styles.image}
            />
          ))}
        </div>
      </div>
    </footer>
  );
};

export const paymentsData = [
  'visa',
  'mastercard',
  'sbp',
  'mir',
  'b',
  'kvitum',
  'bitcoin',
  'trc20',
  'erc20',
  'trx',
  'eth',
];

import { Container } from '@/shared/ui/container';
import styles from './styles.module.scss';
import { useLinks } from '@/shared/context/links.context';

export const Hero = () => {
  const links = useLinks();

  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        <a href={links?.hero_banner} className={styles.banner}>
          <img
            className={styles.image}
            loading="lazy"
            src="/images/main-banner.jpg"
            alt="Удваиваем бонусы за депозит, кешбек до 20%"
          />
        </a>
      </Container>
    </section>
  );
};

export const tableItems = [
  { label: 'Начало работы проекта', value: '2023 год' },
  { label: 'Официальный сайт', value: 'casino7-a.com' },
  { label: 'Лицензия', value: 'Curacao' },
  { label: 'Количество игр', value: '4 500+' },
  { label: 'Зеркало официального сайта', value: 'casino7-a.com' },
  {
    label: 'Провайдеры',
    value:
      'Игровые автоматы, живые дилеры, рулетка, бинго, покер, блэкджек. кости, лотереи, видеопокер, скрэтч-карты',
  },
  {
    label: 'Платежные сервисы',
    value:
      'Виза/МастерКард, МИР, U-Money, Binance Play, Bitcoin, Ethereum, Iether, Piastrix',
  },
  { label: 'Минимальные пополнение', value: '100 RUB' },
  { label: 'Минимальное снятие', value: '100 RUB' },
  { label: 'Сроки зачисления денег', value: 'До 48 часов' },
  {
    label: 'Клиентская поддержка',
    value: 'Телефон горячей линии, live-chat, E-mail',
  },
];
